import React from "react";
import "./carusel.scss"
import { Carousel } from 'react-carousel-minimal';

import Img0 from "./img/DSC_9740.jpg"
import Img1 from "./img/DSC_9746.jpg"
import Img2 from "./img/DSC_9755.jpg"


export function CarouselBaner() {
  const data = [
    {
      image: Img0,
    },
    {
      image: Img1,
    },
    {
      image: Img2,
    },
    
  ];
  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }

  return (

    <Carousel
        data={data}
        time={20000}
        width="100%"
        height="auto"
        captionStyle={captionStyle}
        radius="0"
        slideNumber={false}
        captionPosition="bottom"
        automatic={true}
        dots={false}
        pauseIconColor="white"
        pauseIconSize="40px"
        slideBackgroundColor="darkgrey"
        slideImageFit="cover"
        thumbnails={false}
        thumbnailWidth="100px"
        style={{
            textAlign: "center",
            maxWidth: "100%",
            maxHeight: "100%",
            margin: "0 auto",
        }}
    />

  );
}