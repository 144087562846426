import React from "react";
import { useTranslation } from "react-i18next";
import { CarouselBaner } from "./carusel/carusel";

import "./intro-content.scss"

export function IntroContent(){
    const {t} = useTranslation()
    return(
        <div className="intro-content">
                <CarouselBaner></CarouselBaner>
                <div className="intro-content_case">
                   <div className="intro-content_info">
                        <h1>{t("intro-content.title")}</h1>

                        <span>{t("intro-content.inform")}</span>
                   </div>
                </div>
        </div>
    )
}


