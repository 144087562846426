import React, {useState} from "react";
import { useTranslation } from 'react-i18next';

import "./leng-switch.scss"

export function LanguageSwitcher() {
    const { i18n } = useTranslation();
  
    const [lngs] = useState({
      ua: { nativeName: 'ua', name:"УКР"},
      en: { nativeName: 'en', name:"ENG"},
    });
    // const [lngSwitcherHover, setLngSwitcherHover] = useState(false);
  
    const filteredLngs = Object.values(lngs).filter(
      (lng) => lng.nativeName !== i18n.resolvedLanguage,
    );

    
  
    return (
      <div className="leng-body">
        <div className="leng-case ">
          <button className="leng-button" onClick={()=>{console.log()}}></button>
          {
            filteredLngs.map((lng) => (
              <button
                className="leng-button leng-button_next"
                key={lng.nativeName}
                onClick={() => i18n.changeLanguage(lng.nativeName)}
              >
                {lng.name}
              </button>
            ))
          }
        </div>
      </div>
    );
}
