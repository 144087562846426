import React from "react";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../button/leng/leng-switch";

import "./header.scss"

import Logo from "./img/Logo.svg"

export function Header(){
    const {t,ready } = useTranslation()
    console.log(ready)
    console.log(t("header"))
    return (
        <header>
            <div className="header-case">
                <img src={Logo} alt="" />
                <div className="header-nav">
                    <a href="">{t("header.nav.0")}</a>
                    <a href="">{t("header.nav.1")}</a>
                    <a href="">{t("header.nav.2")}</a>
                </div>
                
            </div>
            <LanguageSwitcher></LanguageSwitcher>
        </header>
    )
}
