import React from "react";

import { IntroContent } from "../component/intro-content/intro-content";

export function ManePage(){
    return (
        <>
            <IntroContent></IntroContent>
        </>
    )
}

