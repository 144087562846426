import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.scss"
import './i18n.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Header } from './component/header/header.jsx';
import { ManePage } from './page-app/mane-page';

const root = ReactDOM.createRoot(document.getElementById('body-website'));

root.render(
  <React.StrictMode>
    <Header></Header>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<ManePage></ManePage>}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

